<template>
  <div v-if="pickingQueue">
    <action-header
      :actions-model="headerModel"
      :page="page"
    />

    <q-card class="q-ma-sm">
      <q-card-section class="row items-center text-subtitle1 border-bottom">
        <div class="col row items-center">
          <div class="col-12 col-md-4">
            <span class="text-bold">{{ $t('Queue No') + ': ' }}</span>

            <span>{{ pickingQueue.id }}</span>
          </div>

          <div class="col-12 col-md-8">
            <span class="text-bold">{{ $t('For') + ': ' }}</span>

            <span>{{ pickingQueue.adapter }}</span>
          </div>

          <div class="col-12 col-md-4">
            <div>
              <strong>
                {{ $t('Name') + ': ' }}
              </strong>

              <span class="d-inline-block">{{ pickingQueue.name || $t('none') }}</span>

              <q-icon
                name="edit"
                class="q-ml-sm"
              />

              <q-popup-edit
                :model-value="pickingQueue.name"
                :title="$t('Edit name')"
              >
                <q-input
                  :model-value="pickingQueue.name"
                  dense
                  autofocus
                  @update:model-value="handleNameChange"
                />

                <div class="q-py-sm text-center">
                  <q-btn
                    color="light-blue-9"
                    :label="$t('Save')"
                    @click="handleSave"
                  />
                </div>
              </q-popup-edit>
            </div>
          </div>

          <div class="col-12 col-md-8">
            <span class="text-bold">{{ $t('On the warehouse') + ': ' }}</span>

            <span>{{ pickingQueue._embedded.warehouse.name }}</span>
          </div>
        </div>

        <div class="text-right">
          <q-btn
            color="dark"
            icon="history"
            size="sm"
            class="q-mr-sm"
            round
            @click="openHistory"
          />

          <q-btn
            v-if="hasSettingsAndFilters"
            color="positive"
            text-color="white"
            class="q-mr-sm"
            size="sm"
            icon="search"
            round
            @click="openFilter(true)"
          />

          <q-btn
            v-if="hasSettingsAndFilters"
            color="dark"
            icon="settings"
            size="sm"
            round
            @click="handleSettingsOpen"
          />
        </div>
      </q-card-section>

      <q-card-section class="q-pa-none">
        <q-carousel
          :model-value="action"
          transition-prev="scale"
          transition-next="scale"
          swipeable
          animated
          class="fit"
        >
          <q-carousel-slide
            name="sequence"
            class="q-pa-none"
          >
            <div class="full-width q-pa-md border-bottom row justify-between items-center">
              <h6 class="q-my-none">
                {{ $t('The queue contains the following waves') + ': ' }}
              </h6>

              <div>
                <q-btn
                  v-if="hasSettingsAndFilters && pickingQueue.settings['wave-state'] === 'preparing' && (!pickingQueue.settings['wave-fill-type'] || pickingQueue.settings['wave-fill-type'] === 'manual')"
                  color="negative"
                  text-color="white"
                  size="sm"
                  class="q-mr-sm"
                  :label="$t('Stop')"
                  no-caps
                  @click="stopWave"
                />

                <q-btn
                  v-if="hasSettingsAndFilters && pickingQueue.settings['wave-state'] === 'finished' && (!pickingQueue.settings['wave-fill-type'] || pickingQueue.settings['wave-fill-type'] === 'manual')"
                  color="positive"
                  text-color="white"
                  class="q-mr-sm"
                  size="sm"
                  :label="$t('Create wave')"
                  no-caps
                  @click="openFilter"
                />

                <q-btn
                  color="dark"
                  size="sm"
                  :label="$t('Filter')"
                  @click="openCloseFilters"
                />
              </div>
            </div>

            <div
              v-if="hasSettingsAndFilters && pickingQueue.settings['wave-state'] === 'preparing'"
              class="border-bottom"
            >
              <div class="text-h6 text-center text-bold q-pa-sm">
                {{ $t('New tasks') + ': ' + tasksCount }}
              </div>

              <q-linear-progress indeterminate />

              <div class="q-pt-sm text-caption text-center">
                {{ $t('Queue is in process of creating new waves and tasks.') }}
              </div>
            </div>

            <filter-collapse
              :is-open="isOpen"
              :options="{
                defaultFilter: filter,
                fields: activatedFields,
                values: {
                  states: states
                },
                style: {
                  noGroups: true
                }
              }"
              @submit="handleFiltersSubmit"
              @close="openCloseFilters"
            />

            <q-table
              class="sticky-header-table"
              row-key="id"
              :no-data-label="$t('No data!')"
              :rows-per-page-label="$t('Rows per page')"
              :rows="pickingSequences"
              :columns="sequenceColumns"
              :v-model:pagination="sequencePagination"
              :loading="pickingSequencesLoading"
              :filter="sequenceFilter"
              :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
              virtual-scroll
              binary-state-sort
              flat
              @request="onSequenceRequest"
            >
              <template v-slot:body="props">
                <q-tr
                  :props="props"
                  class="clickable"
                  @click="handleClickSequence(props.row)"
                >
                  <q-td
                    key="id"
                    :props="props"
                  >
                    {{ props.row.id }}
                  </q-td>

                  <q-td
                    key="extId"
                    :props="props"
                  >
                    {{ props.row.extId }}

                    <div v-if="props.row.stats && props.row.stats['orders-list'] && props.row.stats['orders-list'].length === 1">
                      {{'Order :' + props.row.stats['orders-list'][0]}}
                    </div>
                  </q-td>


                  <q-td
                    key="state"
                    :props="props"
                  >
                    <q-badge
                      :color="stateColors[props.row.state]"
                      :label="$t(props.row.state)"
                    />
                  </q-td>

                  <q-td
                    key="tasks"
                    :props="props"
                  >
                    <div v-if="props.row.tasks">
                      <q-token
                        v-for="(task, i) in getTasks(props.row.tasks)"
                        :key="i"
                        :color="tasksStateColors[task.state]"
                        class="q-mr-sm"
                      >
                        {{ `${task.count}${task.symbol}` }}
                      </q-token>
                    </div>
                  </q-td>

                  <q-td
                    key="orders"
                    :props="props"
                  >
                    {{ getOrders(props.row) }}
                  </q-td>

                  <q-td
                    key="date"
                    :props="props"
                  >
                    {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
                  </q-td>
                </q-tr>
              </template>
            </q-table>
          </q-carousel-slide>

          <q-carousel-slide
            name="tasks"
            class="q-pa-none"
          >
            <tasks-table
              ref="tasksTable"
              :portal="'queue-header'"
              :service="tasksService"
              :queue="pickingQueue.id"
              :sequence="pickingSequence"
              :disabled="(pickingSequence || {}).state !== 'new'"
              @back="handleBackToSequence"
              @deactivate="handleDeactivate"
              @activate="handleActivate"
              @new-task="handleAddTask"
            >
              <q-btn
                v-if="pickingSequence"
                color="dark"
                size="sm"
                class="q-ml-sm"
                :label="$t('Settings')"
                no-caps
                @click="openSequenceSettings"
              />
            </tasks-table>
          </q-carousel-slide>
        </q-carousel>
      </q-card-section>
    </q-card>

    <q-dialog v-model="historyModal">
      <q-card style="min-width: 65vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">
            {{ $t('History') }}
          </div>

          <q-space />

          <q-btn
            v-close-popup
            icon="close"
            flat
            round
            dense
          />
        </q-card-section>

        <q-card-section v-if="pickingQueue">
          <history
            :id="pickingQueue.id"
            :entity="'Orderadmin\\Storage\\Entity\\Tasks\\Queue'"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <queue-filter-modal
      ref="filterModal"
      @submit="handleSubmit"
    />

    <sequence-settings-modal
      ref="sequenceSettingsModal"
      @submit="handleSequenceSubmit"
    />

    <queue-settings-modal
      ref="settingsModal"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'

// Components
import TasksTable from '../../components/dynamic-components/tasks-table.vue'
import FilterCollapse from '../../components/filters/FilterCollapse.vue'
import QueueSettingsModal from '../../components/modals/QueueSettingsModal.vue'
import History from '../../components/history/history.vue'
import QueueFilterModal from '../../components/modals/QueueFilterModal.vue'
import ActionHeader from '../../components/action-header/ActionHeader.vue'
import SequenceSettingsModal from '../../components/modals/SequenceSettingsModal.vue'

// Utils
import { buildQuery } from '../../utils/query-utils'
import _ from 'lodash'

export default {
  name: 'PickingQueue',
  components: {
    TasksTable,
    FilterCollapse,
    QueueSettingsModal,
    History,
    QueueFilterModal,
    ActionHeader,
    SequenceSettingsModal
  },
  data () {
    return {
      historyModal: false,
      tasksCount: 0,
      stateColors: {
        new: 'positive',
        closed: 'negative',
        processing: 'info'
      },
      tasksStateColors: {
        new: 'teal',
        confirmed: 'warning',
        complete: 'positive',
        rejected: 'negative',
      },
      isOpen: false,
      filter: [],
      states: [
        { id: 'new', title: 'New' },
        { id: 'processing', title: 'Processing' },
        { id: 'closed', title: 'Closed' }
      ],
      activatedFields: [
        'id',
        'extId',
        'state'
      ],
      tasksService: this.$service.pickingTask,
      pickingSequence: null,
      action: 'sequence',
      sequencePagination: {
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      sequenceColumns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('ExtId'),
          name: 'extId',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Tasks'),
          name: 'tasks',
          align: 'left'
        },
        {
          label: this.$t('Orders'),
          name: 'orders',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'date',
          align: 'left',
          sortable: true
        }
      ],
      sequenceFilter: '',
      loadedPickingSequences: [],
      pickingAdapters: ['routed_batch', 'batch', 'routed_discrete']
    }
  },
  computed: {
    ...mapGetters([
      'pickingQueue',
      'appOptions',
      'storagePickingTasksEvents',
      'pickingSequences',
      'pickingSequencesPage',
      'pickingSequencesTotalPages',
      'pickingSequencesLoading'
    ]),
    hasSettingsAndFilters () {
      return this.pickingAdapters.includes(this.pickingQueue.adapter)
    },
    page () {
      let manager = 'Picking'

      const managers = {
        allocation: 'Allocation',
        sorting: 'Sorting',
        pallets: 'Pallet Receiving',
        measuring: 'Measuring'
      }

      if (managers[this.pickingQueue.adapter]) {
        manager = managers[this.pickingQueue.adapter]
      }

      return {
        id: this.pickingQueue && this.pickingQueue.id,
        name: this.$t(manager)
      }
    },
    headerModel () {
      let manager = 'Picking'

      const managers = {
        allocation: 'Allocation',
        sorting: 'Sorting',
        pallets: 'Pallet Receiving',
        measuring: 'Measuring'
      }

      if (managers[this.pickingQueue.adapter]) {
        manager = managers[this.pickingQueue.adapter]
      }

      return [
        {
          section: 'BackAction',
          className: 'row items-center justify-start',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              wrapperClassName: 'q-mr-sm',
              label: this.$t('Back'),
              onClick: this.handleBack
            },
            {
              id: 'portal',
              type: 'portal',
              name: 'queue-header'
            },
            {
              id: 'title',
              type: 'text',
              className: 'text-white',
              value: this.$t(`${manager} Manager`)
            }
          ]
        }
      ]
    }
  },
  watch: {
    storagePickingTasksEvents (value) {
      const lastItem = value[value.length - 1]

      if (this.$refs.tasksTable) {
        if (lastItem.rawEntity) {
          this.$refs.tasksTable.updateTasks(lastItem.rawEntity)
        } else {
          this.$refs.tasksTable.update(lastItem.entity)
        }
      } else if (lastItem.event === 'storage.tasks.task.saved') {
        this.handleAddTask()
      }
    }
  },
  mounted () {
    Promise.resolve(!!this.pickingQueue)
      .then(hasQueue => {
        return hasQueue
          ? this.pickingQueue
          : this.loadPickingQueue(this.$route.params.id)
      })
      .then(() => {
        this.loadContent(this.pickingQueue)

        if (this.hasSettingsAndFilters) {
          this.$service.pickingQueue.getSettings(this.pickingQueue.id)
            .then(settings => {
              function getKey (str) {
                return str.split('.')[1]
              }
  
              const hasRequiredField = settings.find(field => field.required && this.pickingQueue.settings[getKey(field.model)])
  
              if (hasRequiredField) {
                this.handleSettingsOpen()
              }
            })
        }

        if (
          this.$route.query.start &&
          this.pickingQueue.settings['wave-fill-type'] !== 'automatic' &&
          this.pickingQueue.settings['wave-state'] !== 'preparing'
        ) {
          this.openFilter()
        }
      })
  },
  unmounted () {
    this.setPickingQueue(null)
  },
  methods: {
    ...mapActions([
      'loadPickingQueue',
      'savePickingQueue',
      'loadPickingSequences'
    ]),
    ...mapMutations([
      'updatePickingQueue',
      'setNewPickingQueue',
      'setPickingQueue',
      'addErrorNotification',
      'upsertPickingSequence'
    ]),
    getTasks (tasks) {
      const priority = {
        new: 1,
        canceled: 2,
        rejected: 3,
        confirmed: 4,
        complete: 5
      }

      return tasks.map(x => {
        return {
          ...x,
          symbol: x.state === 'confirmed'
            ? 'P'
            : x.state[0].toUpperCase()
        }
      }).sort((a, b) => priority[a.state] - priority[b.state])
    },
    getOrders (row) {
      return (row.stats || {}).orders || 0
    },
    handleSequenceSubmit (sequence) {
      this.pickingSequence = sequence
      this.upsertPickingSequence(sequence)
    },
    openSequenceSettings () {
      this.$service.pickingQueue.getSettings(this.pickingQueue.id)
        .then(settings => {
          this.$refs.sequenceSettingsModal.open(this.pickingSequence, this.$service.pickingSequence, { settings, noSave: true })
        })
    },
    loadContent (queue) {
      if (queue.adapter === 'routed_batch') {
        this.loadSequences()
      } else {
        this.action = 'tasks'
      }
    },
    handleAddTask () {
      this.tasksCount += 1
    },
    stopWave () {
      const data = {
        ...this.pickingQueue.settings,
        'wave-fill-type': 'manual',
        'wave-state': 'finished'
      }

      return this.$service.pickingQueue.saveFilters(data, this.pickingQueue.id)
        .then(queue => {
          this.handleSubmit(queue)
        })
    },
    openFilter (noPrepare) {
      const queue = typeof noPrepare === 'boolean' && noPrepare
        ? this.pickingQueue
        : {
          ...this.pickingQueue,
          settings: {
            ...this.pickingQueue.settings,
            'wave-state': 'preparing'
          }
        }

      this.$refs.filterModal.open(_.cloneDeep(queue), this.$service.pickingQueue, 'picking', this.pickingQueue.id)
    },
    createWave () {
      const data = {
        ...this.pickingQueue.settings,
        'wave-state': 'preparing'
      }

      return this.$service.pickingQueue.saveFilters(data, this.pickingQueue.id)
        .then(queue => {
          this.handleSubmit(queue)
        })
    },
    openHistory () {
      this.historyModal = true
    },
    handleSettingsOpen () {
      this.$refs.settingsModal.open(this.pickingQueue, this.$service.pickingQueue , {type: 'picking'})
    },
    handleSubmit (queue) {
      this.setPickingQueue(queue)
      this.$refs.filterModal.close()
    },
    handleActivate (id) {
      return this.$service.pickingSequence.save({ state: 'new' }, id)
        .then(sequence => {
          this.upsertPickingSequence(sequence)
          this.handleBackToSequence()
        })
    },
    handleDeactivate (id) {
      return this.$service.pickingSequence.save({ state: 'canceled' }, id)
        .then(sequence => {
          this.upsertPickingSequence(sequence)
          this.handleBackToSequence()
        })
    },
    handleBackToSequence () {
      this.action = 'sequence'
    },
    openCloseFilters () {
      this.isOpen = !this.isOpen
    },
    handleFiltersSubmit (filter) {
      this.filter = filter
      return this.onSequenceRequest()
    },
    handleSave () {
      this.$service.pickingQueue.save({ name: this.pickingQueue.name }, this.pickingQueue.id)
        .then(queue => {
          this.setPickingQueue(queue)
        })
    },
    handleNameChange (name) {
      this.updatePickingQueue({ name })
    },
    handleClickSequence (item) {
      this.pickingSequence = item
      this.action = 'tasks'
    },
    onSequenceRequest (data = {}) {
      this.sequencePagination = data.pagination || {}
      const query = buildQuery(this.sequencePagination)

      if (!query.filter) {
        query.filter = []
      }

      if (!query['order-by']) {
        query['order-by'] = [
          { type: 'field', field: 'created', direction: 'desc' }
        ]
      }

      query.filter = [...query.filter, ...this.filter]

      if (!query.filter.find(({ field }) => field === 'queue')) {
        query.filter.push({ type: 'eq', field: 'queue', value: this.$route.params.id })
      }

      return this.loadPickingSequences(query)
        .then(({ page, totalItems }) => {
          this.sequencePagination = {
            ...this.sequencePagination,
            page,
            rowsNumber: totalItems
          }
        })
    },
    loadSequences () {
      const pagination = {
        per_page: 25,
        page: 1,
        'order-by': [
          { type: 'field', field: 'created', direction: 'desc' }
        ],
        filter: [
          { type: 'eq', field: 'queue', value: this.$route.params.id }
        ]
      }

      return this.onSequenceRequest({ pagination })
    },
    handleBack () {
      this.$router.back()
    },
    createQuery (params) {
      const query = {
        per_page: 25,
        filter: [],
        ...params
      }

      if (query.search && query.search[query.search.length - 1] !== '*' && query.search[query.search.length - 2] !== ':' && !query.search.includes('%')) {
        query.search += query.search[query.search.length - 1] === ':'
          ? '*'
          : ':*'
      }

      return query
    },
    loadNextItems (search, page, loader) {
      const loaders = {
        pickingSequences: this.loadPickingSequences
      }

      if (typeof loaders[loader] !== 'function') {
        this.addErrorNotification('Items loader is unknown!')
        return
      }

      const query = this.createQuery({ search, page })

      return loaders[loader](query)
    },
    handleChange (type) {
      const types = {
        pickingSequences: {
          getter: 'pickingSequences',
          page: 'pickingSequencesPage',
          loadedValue: 'loadedPickingSequences'
        }
      }

      const current = types[type]

      if (!current) {
        this.addErrorNotification('Unable to handle filter change!')
        return
      }

      if (this[current.page] <= 1) {
        this[current.loadedValue] = this[current.getter]
      } else {
        this[current.loadedValue] = [
          ...this[current.loadedValue],
          ...this[current.getter]
        ]
      }
    }
  }
}
</script>
