<template>
  <div>
    <div class="row items-center q-pa-md">
      <h6 class="q-my-none">
        {{ title }}
      </h6>

      <div v-if="sequence">
        <q-btn
          color="danger"
          size="sm"
          class="q-ml-sm"
          :disable="disabled"
          :label="$t('Cancel this wave')"
          no-caps
          @click="handleDeactivate"
        />
      </div>

      <div class="col q-px-xs">
        <slot />
      </div>

      <div v-if="sequence">
        <portal
          v-if="portal"
          :to="portal"
        >
           <q-btn
            color="dark"
            size="sm"
            class="q-mr-sm"
            no-caps
            :label="$t('Choose another wave')"
            @click="handleBackToSequence"
          />
        </portal>

        <q-btn
          v-else
          color="dark"
          size="sm"
          no-caps
          class="q-mr-sm"
          :label="$t('Choose another wave')"
          @click="handleBackToSequence"
        />
      </div>

      <q-btn
        color="dark"
        size="sm"
        class="q-mr-sm"
        no-caps
        :label="$t('Filter')"
        @click="openCloseFilters"
      />

      <q-btn
        color="dark"
        no-caps
        size="sm"
        class="q-mr-sm"
        :label="$t(isKanban ? 'Table View' : 'Kanban View')"
        @click="changeType"
      />

      <q-btn-dropdown
        color="light-blue-9"
        :label="$t('Bulk')"
        no-caps
        size="sm"
      >
        <q-list>
          <q-item
            clickable
            v-close-popup
            :disable="!hasRejectedTasks"
            @click="handleRejectedToNew"
          >
            <q-item-section>
              <q-item-label>
                {{ $t('Rejected to new') }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </div>

    <filter-collapse
      :is-open="isOpen"
      :options="{
        defaultFilter: filter,
        fields: activatedFields,
        values: {
          states: statuses
        },
        style: {
          noGroups: true
        }
      }"
      @submit="handleFiltersSubmit"
      @close="openCloseFilters"
    />

    <div
      v-if="isKanban"
      class="q-pa-none"
      style="overflow: auto;"
    >
      <div style="min-width: 700px;">
        <tasks-grid
          :items="tasks"
          :loading="isTasksLoading"
          :page="pagination.page"
          :total-pages="totalPages"
          @request="onRequest"
          @assign="handleAssign"
          @state="handleStateChange"
        />
      </div>
    </div>

    <q-table
      v-else
      class="sticky-header-table"
      row-key="id"
      :rows-per-page-label="$t('Rows per page')"
      :rows="tasks"
      :columns="columns"
      v-model:pagination="pagination"
      :loading="isTasksLoading"
      :filter="tableFilter"
      @request="onRequest"
      virtual-scroll
      binary-state-sort
      flat
    >
      <template v-slot:loading>
        <q-inner-loading
          showing
          color="primary"
        />
      </template>

      <template v-slot:body="props">
        <q-tr
          :props="props"
          class="clickable"
        >
          <q-td
            key="id"
            :props="props"
            class="text-subtitle1"
          >
            <strong>{{ props.row.id }}</strong>
          </q-td>

          <q-td
            key="state"
            :props="props"
            class="text-subtitle1"
          >
            <q-badge
              :color="stateColor[props.row.state]"
              :label="$t(props.row.state)"
            />
          </q-td>

          <q-td
            key="executive"
            :props="props"
          >
            <span v-if="props.row._embedded && props.row._embedded.executive">
              {{ props.row._embedded.executive.name }}
            </span>

            <span v-else>--</span>
          </q-td>

          <q-td
            key="updated"
            :props="props"
          >
            {{ $moment(props.row.updated.date).format('HH:mm') }}
          </q-td>

          <q-td
            key="place"
            :props="props"
          >
            <span v-if="props.row._embedded?.place">
              <new-picking-place
                  :data="props.row._embedded.place"
                  :type="props.row._embedded.place.type"
                  badge
              />
            </span>

            <span v-else>--</span>
          </q-td>

          <q-td
            key="order"
            :props="props"
          >
            {{ getOrderID(props.row) || '--' }}
          </q-td>

          <q-td
            key="quantity"
            :props="props"
          >
            {{ `${props.row.scannedCount || 0}/${props.row.count || 0}` }}
          </q-td>

          <q-td
            key="product"
            :props="props"
          >
            <span v-if="props.row._embedded && props.row._embedded.productOffer">
              {{ `${props.row._embedded.productOffer.name} (${props.row._embedded.productOffer.id})` }}
            </span>

            <span v-else>--</span>
          </q-td>

          <q-td
            key="shop"
            :props="props"
          >
            <span v-if="props.row._embedded && props.row._embedded.shop">
              {{ props.row._embedded.shop.name }}
            </span>

            <span v-else>--</span>
          </q-td>
        </q-tr>
      </template>
    </q-table>

    <tasks-patch-modal ref="tasksModal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// Utils
import { buildQuery } from '../../utils/query-utils'

// Components
import FilterCollapse from '../filters/FilterCollapse.vue'
import TasksPatchModal from '../modals/TasksPatchModal.vue'
import TasksGrid from './tasks-grid.vue'
import NewPickingPlace from '@/apps/app/components/picking/new-picking-place.vue'

function createDate (date) {
  return `${date.getFullYear()}-${(date.getMonth() + 1)}-${date.getDate()}`.split('-').map(Number)
}

function getDate () {
  let from = createDate(new Date())

  from[2] -= 2

  if (from[2] < 1) {
    from[2] = 1
  }

  return from.map(value => value < 10 ? `0${value}` : value).join('-')
}

export default {
  name: 'TasksTable',
  emits: ['activate', 'deactivate', 'back', 'new-task'],
  components: {
    NewPickingPlace,
    TasksGrid,
    FilterCollapse,
    TasksPatchModal
  },
  props: {
    portal: {
      type: String,
      default () {
        return null
      }
    },
    service: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    },
    queue: {
      type: [String, Number],
      required: true,
      default () {
        return null
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    sequence: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      tableFilter: '',
      pagination: {
        page: 1,
        rowsPerPage: 250,
        rowsNumber: 250
      },
      stateColor: {
        new: 'bg-lime-5 text-white',
        confirmed: 'bg-lime-4 text-white',
        complete: 'bg-lime-3 text-white',
        rejected: 'bg-red-4 text-dark'
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Executive'),
          name: 'executive',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Time'),
          name: 'updated',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Location'),
          name: 'place',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Order'),
          name: 'order',
          align: 'left'
        },
        {
          label: this.$t('Quantity'),
          name: 'quantity',
          align: 'left'
        },
        {
          label: this.$t('Product'),
          name: 'product',
          align: 'left'
        },
        {
          label: this.$t('Shop'),
          name: 'shop',
          align: 'left',
          sortable: true
        }
      ],
      isOpen: false,
      isKanban: true,
      activatedFields: [
        'updated.from',
        'updated.to',
        'shop',
        'executive',
        'reserve',
        'state',
        'place',
        'productOffer',
        'count.from',
        'count.to',
        'scannedCount.from',
        'scannedCount.to'
      ],
      filter: [
      ],
      tasks: [],
      totalPages: 1,
      isTasksLoading: false,
      statuses: [
        { id: 'new', title: this.$t('New') },
        { id: 'confirmed', title: this.$t('Confirmed') },
        { id: 'complete', title: this.$t('Complete') },
        { id: 'rejected', title: this.$t('Rejected') }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    hasRejectedTasks () {
      return this.tasks.find(x => x.state === 'rejected')
    },
    title () {
      if (this.sequence) {
        return `${this.$t('Wave')}: ${this.sequence.id}`
      }

      return this.$t('Tasks')
    }
  },
  mounted () {
    this.onRequest()
  },
  methods: {
    handleRejectedToNew () {
      const queue = this.tasks.filter(x => x.state === 'rejected')
      const data = {
        queue,
        title: this.$t('Move all rejected tasks to new'),
        description: this.$t('Please wait while process finish!'),
        value: { state: 'new' },
        middlewares: {
          onError () {},
          onSave: (task) => {
            this.updateTasks(task)
          }
        }
      }

      this.$refs.tasksModal.open(this.service, data)
    },
    getOrderID (task) {
      if (task._embedded.order) {
        return task._embedded.order.id
      }

      if (task._embedded.reserve && task._embedded.reserve._embedded && task._embedded.reserve._embedded.order) {
        return task._embedded.reserve._embedded.order._links.self.href.split('/').pop()
      }

      return false
    },
    changeType () {
      this.isKanban = !this.isKanban
      this.onRequest({ pagination: { page: 1 } })
    },
    handleActivate () {
      this.$emit('activate', this.sequence.id)
    },
    handleDeactivate () {
      this.$emit('deactivate', this.sequence.id)
    },
    handleBackToSequence () {
      this.$emit('back')
    },
    handleFiltersSubmit (filter) {
      this.filter = filter
      this.isOpen = false
      this.onRequest({ pagination: { page: 1 } })
    },
    openCloseFilters () {
      this.isOpen = !this.isOpen
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      if (!query.page) {
        query.page = 1
      }

      if (!query.per_page) {
        query.per_page = 250
      }

      if (!query.filter) {
        query.filter = []
      }

      query.filter = [
        ...query.filter,
        ...this.filter
      ]

      if (!query['order-by']) {
        query['order-by'] = [
          { type: 'field', field: 'created', direction: 'desc' }
        ]
      }

      if (this.sequence && !query.filter.find(x => x.field === 'sequence')) {
        query.filter.push({ type: 'eq', field: 'sequence', value: this.sequence.id })
      }

      if (!query.filter.find(x => x.field === 'state')) {
        query.filter.push({ type: 'in', field: 'state', values: ['new', 'confirmed', 'complete', 'rejected'] })
      }

      if (!query.filter.find(x => x.field === 'queue')) {
        query.filter.push({ type: 'eq', field: 'queue', value: this.queue })
      }

      this.isTasksLoading = true

      return this.service.getAll(query)
        .then(({ items, totalItems, page, totalPages }) => {
          this.pagination = {
            page,
            ...this.pagination,
            rowsNumber: totalItems
          }

          this.totalPages = totalPages

          if (this.isKanban && page <= 1) {
            this.tasks = [...this.tasks, ...items]
          } else {
            this.tasks = items
          }
        })
        .finally(() => {
          this.isTasksLoading = false
        })
    },
    updateTasks (task) {
      if (!task._embedded || !task._embedded.queue || task._embedded.queue.id !== this.queue) {
        return
      }

      if (this.sequence && task._embedded.sequence && task._embedded.sequence.id !== this.sequence.id) {
        return
      }

      let isExist = false

      this.tasks = this.tasks.map(t => {
        if (t.id === task.id) {
          isExist = true
          return task
        }

        return t
      })

      if (!isExist) {
        this.tasks = [task, ...this.tasks]
        this.$emit('new-task', task)
      }
    },
    update (id) {
      if (this.isTasksLoading) {
        return
      }

      const query = {
        per_page: 1,
        page: 1,
        filter: [
          { type: 'eq', field: 'id', value: id }
        ]
      }

      return this.service.getAll(query)
        .then(({ items }) => {
          const task = items[0]

          if (!task) {
            return null
          }

          this.updateTasks(task)
          return task
        })
    },
    handleAssign (id, executive) {
      return this.service.save({ executive: executive.id }, id)
    },
    handleStateChange (id, state) {
      return this.service.save({ state }, id)
        .then(task => {
          this.tasks = this.tasks.filter(t => t.id !== id)
          this.tasks = [task, ...this.tasks]
        })
    }
  }
}
</script>
