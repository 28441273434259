<template>
  <div>
    <div class="row text-center border-bottom border-top text-subtitle1">
      <div
        class="col-3 q-pa-md border-right"
        @click="isOpenNew = !isOpenNew"
      >
        {{ `${$t('New')}: ${newTasks.length}` }}

        <q-icon :name="isOpenNew ? 'expand_less' : 'expand_more'" />
      </div>

      <div
        class="col-3 q-pa-md border-right"
        @click="isOpenConfirmed = !isOpenConfirmed"
      >
        {{ `${$t('Confirmed')}: ${confirmedTasks.length}` }}

        <q-icon :name="isOpenConfirmed ? 'expand_less' : 'expand_more'" />
      </div>

      <div
        class="col-3 q-pa-md border-right"
        @click="isOpenComplete = !isOpenComplete"
      >
        {{ `${$t('Complete')}: ${completeTasks.length}` }}

        <q-icon :name="isOpenComplete ? 'expand_less' : 'expand_more'" />
      </div>

      <div
        class="col-3 q-pa-md"
        @click="isOpenRejected = !isOpenRejected"
      >
        {{ `${$t('Rejected')}: ${rejectedTasks.length}` }}

        <q-icon :name="isOpenRejected ? 'expand_less' : 'expand_more'" />
      </div>
    </div>

    <div
      v-if="loading"
      class="row items-center justify-center"
      style="min-height: 300px;"
    >
      <q-spinner
        color="primary"
        size="4rem"
        class="q-mb-md"
      />
    </div>

    <div
      v-if="!loading && items.length <= 0"
      class="row items-center justify-center text-center text-subtitle1"
      style="min-height: 300px;"
    >
      <div>
        {{ $t('No tasks!') }}
      </div>
    </div>

    <div class="row border-bottom">
      <div
        class="col-3"
        :class="type === 'kanban' ? 'q-pa-md' : 'border-right'"
      >
        <tasks-col
          v-for="task in newTasks"
          :key="task.id"
          :task="task"
          :expand="isOpenNew"
          :type="type"
          :class="type === 'kanban' ? 'q-mb-md' : ''"
          @state="handleState"
          @assign="handleAssign"
        >
          <div v-if="task._embedded.productOffer" class="text-center">
            <q-btn
              :label="$t('Product card')"
              color="warning"
              no-caps
              push
              @click="handleShowInfo(task._embedded.productOffer)"
            />
          </div>
        </tasks-col>
      </div>

      <div
        class="col-3"
        :class="type === 'kanban' ? 'q-pa-md' : 'border-right'"
      >
        <tasks-col
          v-for="task in confirmedTasks"
          :key="task.id"
          :task="task"
          :expand="isOpenConfirmed"
          :type="type"
          :class="type === 'kanban' ? 'q-mb-md' : ''"
          @state="handleState"
          @assign="handleAssign"
        >
          <div v-if="task._embedded.productOffer" class="text-center">
            <q-btn
              :label="$t('Product card')"
              color="warning"
              no-caps
              push
              @click="handleShowInfo(task._embedded.productOffer)"
            />
          </div>
        </tasks-col>
      </div>

      <div
        class="col-3"
        :class="type === 'kanban' ? 'q-pa-md' : 'border-right'"
      >
        <tasks-col
          v-for="task in completeTasks"
          :key="task.id"
          :task="task"
          :expand="isOpenComplete"
          :type="type"
          :class="type === 'kanban' ? 'q-mb-md' : ''"
          @state="handleState"
          @assign="handleAssign"
        >
          <div v-if="task._embedded.productOffer" class="text-center">
            <q-btn
              :label="$t('Product card')"
              color="warning"
              no-caps
              push
              @click="handleShowInfo(task._embedded.productOffer)"
            />
          </div>
        </tasks-col>
      </div>

      <div
        class="col-3"
        :class="type === 'kanban' ? 'q-pa-md' : ''"
      >
        <tasks-col
          v-for="task in rejectedTasks"
          :key="task.id"
          :task="task"
          :expand="isOpenRejected"
          :type="type"
          :class="type === 'kanban' ? 'q-mb-md' : ''"
          :has-state-field="true"
          @state="handleState"
          @assign="handleAssign"
        >
          <div v-if="task._embedded.productOffer" class="text-center">
            <q-btn
              :label="$t('Product card')"
              color="warning"
              no-caps
              push
              @click="handleShowInfo(task._embedded.productOffer)"
            />
          </div>
        </tasks-col>
      </div>

      <div v-if="page < totalPages" class="col-12 text-center q-pb-sm">
        <q-btn
          color="light-blue-9"
          no-caps
          :label="$t('Load more')"
          @click="loadNextPage"
        />
      </div>
    </div>

    <offer-modal ref="offerModal" />
  </div>
</template>

<script>
// Components
import TasksCol from './tasks-col.vue'
import OfferModal from '../modals/OfferModal.vue'

export default {
  name: 'TasksGrid',
  emits: ['state', 'assign'],
  components: {
    TasksCol,
    OfferModal
  },
  props: {
    type: {
      type: String,
      default () {
        return 'kanban'
      }
    },
    loading: {
      type: Boolean,
      default () {
        return false
      }
    },
    items: {
      type: Array,
      default () {
        return []
      }
    },
    page: {
      type: Number,
      default () {
        return 1
      }
    },
    totalPages: {
      type: Number,
      default () {
        return 1
      }
    }
  },
  data () {
    return {
      tasks: {},
      isOpenNew: false,
      isOpenConfirmed: false,
      isOpenComplete: false,
      isOpenRejected: false
    }
  },
  computed: {
    newTasks () {
      return this.tasks.new || []
    },
    confirmedTasks () {
      return this.tasks.confirmed || []
    },
    completeTasks () {
      return this.tasks.complete || []
    },
    rejectedTasks () {
      return this.tasks.rejected || []
    }
  },
  watch: {
    items (value) {
      this.setTasks(value)
    }
  },
  mounted () {
    this.setTasks(this.items)
  },
  methods: {
    loadNextPage () {
      this.$emit('request', { pagination: { page: this.page + 1 } })
    },
    handleShowInfo (offer) {
      this.$refs.offerModal.open(offer)
    },
    setTasks (items) {
      this.tasks = items.reduce((acc, item) => {
        if (!acc[item.state]) {
          acc[item.state] = []
        }

        acc[item.state].push(item)

        return acc
      }, {})
    },
    handleState (id, state) {
      this.$emit('state', id, state)
    },
    handleAssign (id, executive) {
      this.$emit('assign', id, executive)
    }
  }
}
</script>
