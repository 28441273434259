<template>
  <q-card
    :class="`${color} ${classNames}`"
    class="q-pb-sm"
  >
    <q-card-section
      v-if="task.created"
      class="row justify-between items-center q-py-sm text-subtitle2 border-bottom"
      @click="handleExpand"
    >
      <div class="text-subtitle1 text-bold">
        {{ $t('Time') + ': ' + $moment(task.updated.date).format('HH:mm') }}
      </div>

      <q-icon :name="isOpen ? 'expand_less' : 'expand_more'" />
    </q-card-section>

    <div v-if="task._embedded">
      <q-card-section class="q-py-none">
        <div
          v-if="task._embedded.executive"
          class="q-mt-xs"
        >
          {{ $t('Assigned to') + `: ${task._embedded.executive.name} (${task._embedded.executive.id})` }}
        </div>

        <div
          v-if="task._embedded.place"
          class="q-mt-xs row items-center"
        >
          <span>
            {{ $t('Location') + ': ' }}
          </span>

          <new-picking-place
            :data="task._embedded.place"
            class="q-mx-xs"
            dense
          />
          
          <span>
            ({{ task._embedded.place.id }})
          </span>
        </div>
      </q-card-section>

      <q-slide-transition>
        <q-card-section
          v-show="isOpen"
          class="q-py-none"
        >
          <div v-if="task._embedded">
            <div
              v-if="orderID"
              class="q-mt-xs"
            >
              {{ $t('Order') + `: ${orderID}` }}
            </div>

            <div class="q-mt-xs">
              {{ `${$t('Quantity')}: ${task.scannedCount || 0}/${task.count || 0}` }}
            </div>

            <div
              v-if="task._embedded.shop"
              class="q-mt-xs"
            >
              {{ $t('Store') + `: ${task._embedded.shop.name} (${task._embedded.shop.id})` }}
            </div>

            <div
              v-if="task._embedded.productOffer"
              class="q-mt-xs"
            >
              <div>
                {{ $t('Product') + `: ${task._embedded.productOffer.name} (${task._embedded.productOffer.id})` }}
              </div>

              <div class="q-mt-xs">
                {{ $t('Barcode') + `: ${barcode}` }}
              </div>
            </div>
          </div>

          <div class="q-mt-xs">
            {{ $t('Date') + ': ' + $moment(task.created.date).format(appOptions.formats.date) }}
          </div>

          <div class="row q-pt-xs q-mt-xs border-top">
            <div class="col-12 col-md q-pa-xs text-center">
              <slot></slot>
            </div>

            <div
               v-if="!disabled && task._embedded"
              class="col q-pa-xs text-center"
            >
              <q-btn
                color="light-blue-9"
                :label="$t('Assign to')"
                push
                no-caps
              >
                <q-popup-edit
                  :model-value="task._embedded"
                  :title="$t('Assigned to')"
                  content-style="overflow: visible;"
                >
                  <form-builder :schema="schema" />
                </q-popup-edit>
              </q-btn>
            </div>

            <div
              v-if="hasStateField"
              class="col q-pa-xs text-center"
            >
              <q-btn
                :label="$t('To pick')"
                color="positive"
                push
                no-caps
                @click="changeStateToNew"
              />
            </div>
          </div>
        </q-card-section>
      </q-slide-transition>
    </div>
  </q-card>
</template>
<script>
// Vuex
import { mapGetters } from 'vuex'

// Components
import newPickingPlace from '../picking/new-picking-place.vue'

export default {
  name: 'TasksCol',
  components: {
    newPickingPlace
  },
  emits: ['state', 'assign'],
  props: {
    hasStateField: {
      type: Boolean,
      default () {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    task: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    },
    expand: {
      type: Boolean,
      default () {
        return false
      }
    },
    type: {
      type: String,
      default () {
        return 'kanban'
      }
    }
  },
  data () {
    return {
      isOpen: false,
      loadedUsers: [],
      states: ['new'],
      colors: {
        new: 'bg-lime-5 text-dark',
        confirmed: 'bg-lime-4 text-dark',
        complete: 'bg-lime-3 text-dark',
        rejected: 'bg-red-4 text-dark'
      }
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    barcode () {
      const barcodes = Array.isArray(this.task._embedded.productOffer.barcodes)
        ? this.task._embedded.productOffer.barcodes
        : []

      return barcodes[0] || this.$t('No barcode')
    },
    classNames () {
      if (this.type === 'kanban') {
        return ''
      }

      return 'no-border-radius no-box-shadow'
    },
    orderID () {
      if (this.task._embedded.order) {
        return this.task._embedded.order.id
      }

      if (this.task._embedded.reserve && this.task._embedded.reserve._embedded && this.task._embedded.reserve._embedded.order) {
        return this.task._embedded.reserve._embedded.order._links.self.href.split('/').pop()
      }

      return false
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Assigne to'),
                wrapperStyleClasses: !this.hasStateField
                  ? 'col-12 q-pa-xs'
                  : 'd-none',
                field: 'executive',
                value: this.task._embedded.executive,
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      {
                        type: 'innerjoin',
                        field: 'roles',
                        parentAlias: 'u',
                        alias: 'r'
                      },
                      {
                        field: 'id',
                        alias: 'r',
                        type: 'eq',
                        value: 7
                      }
                    ]
                  }

                  return this.$service.user.getAll(query)
                },
                onChange: (executive) => {
                  this.$emit('assign', this.task.id, executive)
                }
              }
            ]
          }
        ]
      }
    },
    color () {
      return this.colors[this.task.state]
    }
  },
  watch: {
    expand (newVal) {
      this.isOpen = newVal
    }
  },
  methods: {
    changeStateToNew () {
      this.$emit('state', this.task.id, 'new')
    },
    handleExpand () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>
